import * as React from "react";
import soteriaLogoWhite from "../Images/soteriaLogoWhite.svg";
import sotDotsLarge from "../Images/sotDotsLarge.svg";
import successLogo from "../Images/successLogo.svg";
import classes from "./TermsNConditions.module.css";
import bgimgTermsandConditions from "../Images/bgimgTermsandConditions.jpeg";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useNavigate } from "react-router-dom";

function Success() {
  const navigate = useNavigate();
  const navigateToLogin = () => {
    // 👇️ navigate to custom login UI
    console.log('navigated to custom login...');
    navigate('/Login');
  };

  return (
    <body style={{ backgroundImage: `url(${bgimgTermsandConditions})` , backgroundSize:'cover', height:"100vh" , boxShadow: "inset 0 0 0 2000px rgb(20 20 20 / 70%)" }}>
      <div>
        <img
          style={{ width: "120px", margin: "30px" }}
          src={soteriaLogoWhite}
          alt="Logo"
        />
      </div>
      <div className={classes.TnCbgContainer}>
        <div>
          <img
            style={{
              top: "30px",
              margin: "30px",
              position: "relative",
              left: "270px ",
            }}
            src={successLogo}
            alt="Logo"
          />
          <HighlightOffIcon onClick={navigateToLogin}
              sx={{ position: "absolute", height: "20px", top: "15px", right:"10px", cursor:"pointer", ":hover": {backgroundColor: "#ffff9b", color: "#fd0808" } }}
              src={HighlightOffIcon}
              alt="Close"
            />
        </div>

        <h4 style={{ textAlign: "center" }}>
          Welcome Onboard! Your email account registration was successful
        </h4>
        <div style={{ textAlign: "center" }}>
          We have sent an email to your inbox to confirm your registration.
          Please follow the instructions provided to activate your account.
        </div>
      </div>
      <div>
        <img
          style={{
            width: "195px",
            position: "fixed",
            bottom: "-200px",
            right: "0",
          }}
          src={sotDotsLarge}
          alt="Logo"
        />
      </div>
    </body>
  );
}

export default Success;
