import {Routes, Route} from 'react-router-dom';
import Success from './components/Success';
import Login from './components/Login';
import Registration from './components/Registration';
import TermsNConditions from './components/TermsNConditions';
import React, { useEffect } from 'react';

export default function App() {
  useEffect(() => {
    document.title = 'Soteria';
  }, []);

  return (
      <div>
        <Routes>
          <Route path="Success" element={<Success />} />
          <Route path="Registration" element={<Registration />} />
          <Route path="/" element={<Login />} />
          <Route path="Login" element={<Login />} />
          <Route path="TermsNConditions" element={<TermsNConditions />} />
        </Routes>
      </div>
  );
}

