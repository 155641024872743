import * as React from "react";
import classes from "./Login.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import soteriaLogo from "../Images/soteriaLogo.svg";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import iconLogin from "../Images/iconLogin.jpeg";
import sotDots from "../Images/sotDots.svg";
import sotDotsLarge from "../Images/sotDotsLarge.svg";
import sotDotsLeft from "../Images/sotDotsLeft.svg";
import { useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();

  const navigateToTnC = () => {
    // navigate to /registration UI
    console.log('navigated to terms n condition...');
    navigate('/TermsNConditions');
  };

  const navigateToSotLogin = () =>{
    console.log('navigating to Soteria login page...');
    window.location.href = 'https://soteria.m-tg.com/';
  }

  return (
    <div>
      <div className={classes.plainContainer}>
        <div className={classes.logoContainer}>
          <img
            style={{ width: "120px", margin: "30px" }}
            src={soteriaLogo}
            alt="Logo"
          />
        </div>
      </div>
      <CssBaseline />
      <Container maxWidth="sm">
        <Box
          sx={{
            bgcolor: "#ffffff",
            height: "55vh",
            position: "relative",
            zIndex: "1",
            padding: "40px 80px 40px 80px",
            width: "455px",
            boxShadow: "0px 10px 12px rgba(0, 0, 0, 0.16)",
          }}
        >
          <div className={classes.formElements}>
            <Avatar
              sx={{ marginLeft: "36%", width: "50px", height: "50px" }}
              src={iconLogin}
              alt="User"
            />
            <Button onClick={navigateToSotLogin}
              variant="contained"
              sx={{
                width: "265px",
                marginTop: "60px",
                textTransform: "capitalize",
                bgcolor: "#218FCA",
              }}
            >
              Login
            </Button>
            <p style={{ paddingLeft: "120px" }}>Or</p>
            <Button onClick={navigateToTnC}
              variant="contained"
              sx={{
                width: "265px",
                textTransform: "capitalize",
                bgcolor: "#218FCA",
              }}
            >
              Register
            </Button>
          </div>
        </Box>
      </Container>

      <div className={classes.bgContainer}>
        <img
          style={{ width: "100px", marginTop: "35px", marginLeft: "275px" }}
          src={sotDots}
          alt="Logo"
        />
      </div>
      <footer>
        <img
          style={{ width: "80px", bottom: "0", left: "0", position: "fixed" }}
          src={sotDotsLeft}
          alt="Logo"
        />
        <img
          style={{
            width: "95px",
            bottom: "-40px",
            left: "50%",
            position: "fixed",
          }}
          src={sotDots}
          alt="Logo"
        />
        <img
          style={{
            width: "235px",
            height: "340px",
            bottom: "85px",
            right: "0",
            position: "fixed",
          }}
          src={sotDotsLarge}
          alt="Logo"
        />
      </footer>
    </div>
  );
}

export default Login;
