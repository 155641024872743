//This is for registration UI
import * as React from "react";
import ReactDOM from 'react-dom';
import classes from "./Registration.module.css";
import { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import soteriaLogo from "../Images/soteriaLogo.svg";
import Button from "@mui/material/Button";
import sotDots from "../Images/sotDots.svg";
import sotDotsLeft from "../Images/sotDotsLeft.svg";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 12,
    width: "250px",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.1rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

function Registration(){
  const navigate = useNavigate();

  const navigateToLogin = () => {
    // navigate to /Login
    navigate('/Login');
  };
 const [serviceTag, setServiceTag] = useState("");
  const [email, setEmail] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [errorMessage, setErrorMessage] = React.useState("");

  const yupValidation = Yup.object().shape({
    serviceTag: Yup.string()
      .required('*Please enter the service tag')
      .min(7, '*Service tag must be 7 characters'),
    first_name: Yup.string()
      .required('*Please enter your first name')
      .min(2, '*Please enter your first name'),
    last_name: Yup.string()
      .required('*Please enter your last name'),
    email: Yup.string().required('*Email id is mandatory').email('*Please enter a valid email' + '\n' + '(eg- your_name@xyz.com)')
  })
  const formOptions = { resolver: yupResolver(yupValidation) }
  const { register, handleSubmit, reset, formState } = useForm(formOptions)
  const { errors } = formState
  const onSubmit = async (e) => {
   // e.preventDefault();
    try {
      let res = await fetch(
        "https://q69ur667dc.execute-api.us-east-2.amazonaws.com/prod/register",
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            serviceTag: serviceTag,
            email: email,
            first_name: first_name,
            last_name: last_name,
          }),
        }
      );
      let resJson = await res.json();
      console.log("value of resJson-->" + JSON.stringify(resJson));
      if (res.status === 200) {
        setServiceTag("");
        setEmail("");
        setFirst_name("");
        setLast_name("");
        if((resJson.succses === true)){
          console.log("success....");
        navigate('/Success');
        }
      }
       if(resJson.message === "User already exists") {
        setTimeout(navigateToLogin, 5000);
        setErrorMessage("User already exists! Please Login...");
        console.log("User already exists! Navigating to Login...");
      }

      else if (resJson.message === "Runtime error") {
        console.log("Runtime error captured...........");
        setErrorMessage("Somthing went wrong! Please try again!");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <div className={classes.plainContainer}>
        <div className={classes.logoContainer}>
          <img
            style={{ width: "120px", margin: "30px" }}
            src={soteriaLogo}
            alt="Logo"
          />
        </div>
      </div>
      <CssBaseline />
      <Container maxWidth="sm">
        <Box
          sx={{
            bgcolor: "#ffffff",
            height: "auto",
            left: "55px",
            position: "relative",
            zIndex: "1",
            padding: "10px 40px 35px 50px",
            width: "400px",
            top: "-50px",
            boxShadow: "0px 10px 12px rgba(0, 0, 0, 0.16)",
          }}
        >
          <div className={classes.formElements}>
            <p style={{ fontSize: "x-large", marginBottom: "12px" }}>
              Registration
            </p>

           
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl sx={{ marginTop: "10px" }} variant="standard">
                <InputLabel
                  sx={{ paddingTop: "10px" }}
                  shrink
                  htmlFor="service-tag-number"
                >
                  Service Tag Number
                </InputLabel>
                <BootstrapInput
                  placeholder="Enter Service Tag Number"
                  id="service-tag-number"
                  value={serviceTag}
                  className={`form-control ${errors.serviceTag ? 'is-invalid' : ''}`}
            {...register('serviceTag')}
                  onChange={(e) => setServiceTag(e.target.value)}
                />
                <div style={{ fontSize: "12px" , color: "#c3432c"}}>{errors.serviceTag?.message}</div>
              </FormControl>
              <FormControl sx={{ marginTop: "10px" }} variant="standard">
                <InputLabel
                  sx={{ paddingTop: "10px" }}
                  shrink
                  htmlFor="first-name"
                >
                  First Name
                </InputLabel>
                <BootstrapInput
                  placeholder="Enter First Name"
                  id="first-name"
                  value={first_name}
                  className={`form-control ${errors.first_name ? 'is-invalid' : ''}`}
            {...register('first_name')}
                  onChange={(e) => setFirst_name(e.target.value)}
                />
                <div style={{ fontSize: "12px" , color: "#c3432c"}}>{errors.first_name?.message}</div>
              </FormControl>

              <FormControl sx={{ marginTop: "10px" }} variant="standard">
                <InputLabel
                  sx={{ paddingTop: "10px" }}
                  shrink
                  htmlFor="last-name"
                >
                  Last Name
                </InputLabel>
                <BootstrapInput
                  placeholder="Enter Last Name"
                  id="last-name"
                  value={last_name}
                  className={`form-control ${errors.last_name ? 'is-invalid' : ''}`}
            {...register('last_name')}
                  onChange={(e) => setLast_name(e.target.value)}
                />
                <div style={{ fontSize: "12px" , color: "#c3432c"}}>{errors.last_name?.message}</div>
              </FormControl>

              <FormControl sx={{ marginTop: "10px" }} variant="standard">
                <InputLabel
                  sx={{ paddingTop: "10px" }}
                  shrink
                  htmlFor="email-address"
                >
                  Email Address
                </InputLabel>
                <BootstrapInput
                  placeholder="Enter Email Address"
                  id="email-address"
                  value={email}
                  className={`form-control ${errors.email ? 'is-invalid' : ''}`}
            {...register('email')}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div style={{ fontSize: "12px" , color: "#c3432c"}}>{errors.email?.message}</div>
              </FormControl>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  width: "276px",
                  textTransform: "capitalize",
                  bgcolor: "#218FCA",
                  marginTop: "30px",
                }}
              >
                Register
              </Button>
            </form>
            {errorMessage && <div style={{ fontSize: 'smaller', color: '#ff6347', paddingTop: '10px' }} className="error"> {errorMessage} </div>}
          </div>
        </Box>
      </Container>

      <div className={classes.bgRegistrationContainer}>
        <img
          style={{ width: "100px", marginTop: "35px", marginLeft: "475px" }}
          src={sotDots}
          alt="Logo"
        />
      </div>
      <footer>
        <img
          style={{ width: "80px", bottom: "0", left: "0", position: "fixed" }}
          src={sotDotsLeft}
          alt="Logo"
        />
      </footer>
    </div>
  );
}

export default Registration;
