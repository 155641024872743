import * as React from "react";
import classes from "./TermsNConditions.module.css";
import soteriaLogoWhite from "../Images/soteriaLogoWhite.svg";
import { pink } from "@mui/material/colors";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import sotDotsLarge from "../Images/sotDotsLarge.svg";
import bgimgTermsandConditions from "../Images/bgimgTermsandConditions.jpeg";
import { useNavigate } from "react-router-dom";
import{useState} from 'react';

const label = { inputProps: { "aria-label": "Checkbox demo" } };

function TermsNConditions() {

  const [change, setChange] = useState(true);
function buttonHandler(){
  setChange(!change)
}

  const navigate = useNavigate();
  const navigateToRegistration = () => {
    // 👇️ navigate to /registration UI
    console.log('navigated to Registration...');
    navigate('/Registration');
  };

  const navigateToLogin = () => {
    // 👇️ navigate to custom login UI
    console.log('navigated to custom login...');
    navigate('/Login');
  };

  return (
    <body style={{ backgroundImage: `url(${bgimgTermsandConditions})` , backgroundSize:'cover', height:"100vh" , boxShadow: "inset 0 0 0 2000px rgb(20 20 20 / 70%)" }}>
    <div>
      <div>
        <img
          style={{ width: "120px", margin: "30px" }}
          src={soteriaLogoWhite}
          alt="Logo"
        />
      </div>
      <div className={classes.TnCbgContainer}>
        <h4> Licensed Server Software Terms and Conditions</h4>
        <div
          style={{ paddingLeft: "50px", height: "320px", overflow: "overlay" }}
        >
          <article>
            Please read this Licensed Server Software Terms and Conditions
            (“Agreement”) carefully before using the server and service. This is
            a legally binding agreement between you (“you” or “Licensee”) and
            McQueen Technology Group, LLC (“MTG”). By using any portion of the
            server and/or service, you accept all terms and conditions of this
            Agreement. If you have not read the Agreement, do not understand or
            agree to be bound by the Agreement, or are not able to consent to be
            bound by the Agreement (e.g., if you are not old enough to enter
            into a binding legal contract), do not use the server and/or
            service.
          </article>

          <article>
            <b>1. License Grant:</b> Subject to the terms and conditions of this
            Agreement, MTG grants you a limited, revocable, personal, perpetual,
            non-exclusive, non-transferable (except as provided in Section 3
            below), non-assignable (except as provided in Section 3 below),
            non-sublicensable license to access and use, solely for your
            internal business purposes, the server and Software as a Service
            (SaaS) (“Software”). The license granted under this Section 1 shall
            be referred to as the “License.”
          </article>
          <article>
            <b>2. Restrictions:</b> You may not, and may not allow any third
            party to, resell, transfer (except as provided in Section 3 below),
            rent or lease the Software without the specific agreement of MTG.
            You may not, and may not allow any third party to, reverse engineer,
            decompile, disassemble or otherwise reduce the Software to any human
            perceivable form. You shall not remove or alter any copyright notice
            or any other notices that appear on the Software. You may not, and
            may not allow any third party to, modify, create derivative works
            based upon, adapt, translate, rent, lease, make available on a
            subscription or SaaS basis or loan the Software or any part
            thereof.You shall use best efforts to safeguard the Software so as
            to insure that no unauthorized party has access to the Software and
            that no party makes any unauthorized copy of the Software. You shall
            use best efforts to assist MTG in identifying and preventing any
            unauthorized use, copying, or disclosure of Software or any portions
            thereof or any of the algorithms or the logic contained in the
            Software. You shall advise MTG immediately in the event you learn,
            or have reason to believe, that any party to whom you have given
            access to the Software has violated, or intends to violate, the
            terms of this Agreement, and you will cooperate with MTG in seeking
            injunctive or other equitable relief against any such person.You
            shall be solely responsible to MTG for the observance and compliance
            with all terms and conditions of this Agreement by your employees,
            contractors, service providers, agents and any other third party who
            has been permitted access to the Software as a result of your action
            or inaction, whether or not such party is actually permitted to have
            such access under the terms of this Agreement.
          </article>
          <article>
            <b>3. Transfer and Assignment:</b> You may transfer the Software,
            and assign your License to the Software, with a copy of this
            Agreement and all written materials to another party only on a
            permanent basis and only if the other party accepts the complete
            terms and conditions of this Agreement. MTG must be notified of the
            intended transfer in writing, at least thirty (30) days prior to the
            transfer. MTG may assign or delegate any right, interest,
            obligation, or duty under this Agreement upon notice to you. Any
            purported transfer or assignment that does not comply with this
            section shall be void and ineffective.
          </article>
          <article>
            <b>4. Pricing and Payment:</b> Your purchase includes the server and
            a one-year subscription to use the service (SaaS). During your
            subscription, and any subsequent renewals thereof, you may use the
            server and software and further gain access to the MTG portal for
            security scans and reports thereof.
          </article>
          <article>
            <b>5. Term and Termination:</b> This Agreement will remain in effect
            for one year and will be renewed automatically for further one year
            terms unless cancelled by you. It is your responsibility to give
            notice of cancellation if you do not wish to renew at the end of the
            initial or any renewal term. If you breach this Agreement, the
            License terminates automatically. In the event your subscription
            ends without a renewal, the License terminates. Upon termination,
            MTG reserves the right to connect to your server and perform a wipe
            and/or reimage thereof.
          </article>
          <article>
            <b>6. Limited Warranty:</b> MTG warrants the media on which the
            Software is provided to be free from defects in materials under
            normal use for a period of ninety (90) days from the date of
            delivery as evidenced by your purchase receipt. Your sole and
            exclusive remedy for breach of this warranty is replacement of the
            media upon delivery of the defective media to MTG.EXCEPT AS
            EXPRESSLY WARRANTED HEREIN, THE SOFTWARE IS PROVIDED “AS IS” WITHOUT
            ANY WARRANTY OF ANY KIND, EITHER EXPRESS, IMPLIED, OR STATUTORY,
            INCLUDING BUT NOT LIMITED TO WARRANTIES OF NON-INFRINGEMENT,
            MERCHANTABILITY, DESIGN, OR FITNESS FOR A PARTICULAR PURPOSE, OR
            WARRANTIES ARISING FROM A PARTICULAR COURSE OF DEALING, USAGE, OR
            TRADE PRACTICE, WITH REGARD TO THE SOFTWARE AND ACCOMPANYING
            DOCUMENTATION. NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY MTG
            SHALL CREATE A WARRANTY AND YOU MAY NOT RELY UPON SUCH INFORMATION
            OR ADVICE. MTG DOES NOT WARRANT THAT THE SOFTWARE WILL MEET YOUR
            REQUIREMENTS OR THAT OPERATION OF THE SOFTWARE WILL BE UNINTERRUPTED
            OR ERROR-FREE. THE ENTIRE RISK AS TO THE QUALITY AND PERFORMANCE OF
            THE SOFTWARE SHALL BE WITH YOU.
          </article>
          <article>
            <b>7. Ownership:</b> All right, title, interest, ownership, and
            patent rights, trademark rights, copyrights, and trade secret rights
            (collectively, “Intellectual Property Rights”) in and to the
            Software and all copies, modifications, enhancements, and derivative
            works of the Software vest in and belong to MTG and/or its
            suppliers. This Agreement confers no title or ownership in the
            Software or any copies, modifications, enhancements, or derivative
            works of the Software and is not a sale of any rights. The
            structure, organization, and code of the Software are the valuable
            trade secrets, confidential information, and proprietary information
            of MTG and/or its suppliers. The Software is protected by law,
            including without limitation the copyright laws of the United States
            and other countries, and by international treaty provisions. Except
            as expressly stated herein, this Agreement does not grant you any
            rights in the Software or any copies, modifications, enhancements,
            and derivative works of the Software.
          </article>
          <article>
            <b>8. Limitation of Liability:</b> a. IN NO EVENT WILL MTG, ITS
            AFFILIATES, OR ITS AND THEIR OFFICERS, DIRECTORS, EMPLOYEES, AGENTS,
            CONSULTANTS, OR ITS SUPPLIERS BE LIABLE TO YOU (OR TO ANY OTHER
            THIRD PARTY PERMITTED ACCESS TO OR USE OF THE SOFTWARE UNDER THIS
            AGREEMENT) FOR ANY INDIRECT, CONSEQUENTIAL, INCIDENTAL, EXEMPLARY,
            PUNITIVE, OR SPECIAL DAMAGES, WHETHER ARISING IN CONTRACT, EQUITY,
            NEGLIGENCE, TORT, OR OTHERWISE (INCLUDING BREACH OF WARRANTY,
            NEGLIGENCE, AND STRICT LIABILITY IN TORT), INCLUDING WITHOUT
            LIMITATION DAMAGES ARISING FROM DELAY; LOSS OF GOODWILL; LOSS OF,
            DAMAGE TO, OR INTERRUPTION OF DATA OR NETWORKS; FOR ANY LOSS OF
            BUSINESS PROFITS, REVENUE, OR SAVINGS (ACTUAL OR ANTICIPATED); LOSS
            OF INFORMATION OR USE; OR INABILITY TO USE ANY SOFTWARE PROVIDED BY
            MTG PURSUANT TO THIS AGREEMENT, EVEN IF YOU OR ANY OTHER THIRD
            PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.b. IN
            NO EVENT SHALL THE AGGREGATE LIABILITY OF MTG, ITS AFFILIATES, AND
            ITS AND THEIR OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, CONSULTANTS,
            AND SUPPLIERS FROM ANY AND ALL CLAIMS ARISING UNDER THIS AGREEMENT
            EXCEED THE AGGREGATE OF THE FEES PAID TO MTG FOR THE SOFTWARE FOR
            WHICH THE CAUSE OF ACTION ACCRUED IN THE TWELVE (12) MONTH PERIOD
            PRECEDING THE DATE WHEN THE CAUSE OF ACTION ACCRUED. THIS LIMITATION
            ON THE AMOUNT OF LIABILITY SHALL APPLY WHETHER A CLAIM ARISES IN
            CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER LEGAL
            THEORY, INCLUDING BREACH OF WARRANTY. THIS LIMITATION SHALL ALSO
            SPECIFICALLY SURVIVE A FAILURE OF ESSENTIAL PURPOSE OF ANY REMEDIES
            THAT MAY BE PROVIDED IN THIS AGREEMENT.
          </article>
          <article>
            <b>9. Compliance with Laws: </b>You shall use the Software made
            available by MTG hereunder in compliance with all applicable
            federal, state, and local laws, and regulations. You may not export
            or reexport the Software except as authorized by United States law
            and the laws of the jurisdiction in which the Software was obtained.
          </article>
          <article>
            <b>10. Governing Law and Choice of Venue: </b>The construction,
            interpretation, and performance of this Agreement shall be governed
            by the laws of the State of Illinois, without application of its
            conflicts of laws principles. All actions under this Agreement shall
            be brought in a court of competent subject matter jurisdiction in
            the State of Illinois and you agree to accept and submit to the
            personal jurisdiction of such court. Under no circumstances shall
            the Uniform Computer Information Transactions Act (UCITA), or any
            version thereof, apply to this Agreement.
          </article>
          <article>
            <b>11. Entire Agreement: </b>Except for any applicable Purchase
            Agreement, this Agreement is the entire agreement between you and
            MTG regarding the license of Software and it supersedes all prior or
            contemporaneous oral or written communications, proposals, and
            representations with respect to the license of such Software. The
            terms of this Agreement may only be changed by a written amendment
            signed by an officer of MTG.
          </article>
          <article>
            <b>12. Severability: </b>If any part of this Agreement is found void
            or unenforceable, it will not affect the validity of the balance of
            this Agreement, which shall remain valid and enforceable according
            to its terms.
          </article>
          <article>
            <b>13. Non-Waiver:</b> MTG’s failure at any time to enforce any of
            the provisions of this Agreement or any right or remedy available
            hereunder or at law or equity, or to exercise any option herein
            provided will in no way be construed to be a waiver of such
            provisions, rights, remedies, or options or in any way to affect the
            validity of this Agreement.
          </article>
          <article>
            <b>14. Survival:</b> Any terms of this Agreement, which by their
            nature extend beyond the date this Agreement ends, shall remain in
            effect until fulfilled and shall apply to respective permitted
            successors and assignees.
          </article>
        </div>

        <footer className={classes.custom}>
          <Stack direction="row" spacing={2} padding="10px" backgroundColor="#f2efef"
          >
            <Checkbox
              {...label}
              onChange={buttonHandler}
              sx={{
                color: pink[800],
                "&.Mui-checked": {
                  color: pink[600],
                },
                marginLeft: "34px",
                "& .MuiSvgIcon-root": { fontSize: 14 },
              }}
            />
            <span
              style={{
                marginRight: "230px",
                paddingTop: "6px",
                fontSize: "smaller",
                marginLeft: "0",
              }}
            >
              Accept Terms of Use
            </span>

            <Button onClick={navigateToLogin}
              variant="contained"
              sx={{
                bgcolor: "#EC1C24",
                padding: "4px 12px 4px 12px",
                textTransform: "capitalize",
              }}
            >
              Not Accept
            </Button>
            <Button onClick={navigateToRegistration}
            disabled={change}
              variant="contained"
              sx={{
                bgcolor: "#337AB7",
                padding: "4px 12px 4px 12px",
                textTransform: "capitalize",
              }}
            >
              Accept
            </Button>
          </Stack>
        </footer>
      </div>
      <div>
        <img
          style={{
            width: "195px",
            position: "fixed",
            bottom: "-200px",
            right: "0",
          }}
          src={sotDotsLarge}
          alt="Logo"
        />
      </div>
    </div>
    </body>
  );
}

export default TermsNConditions;
